import PropTypes from 'prop-types';
import React from 'react';

const Footer = props => (
  <footer id="footer" style={props.timeout ? { display: 'none' } : {}}>
    <p className="copyright">
      &copy; HYTECH LLC -{' '}
      <a href="mailto:support@hytechllc.com">support@hytechllc.com</a>
    </p>
  </footer>
);

Footer.propTypes = {
  timeout: PropTypes.bool
};

export default Footer;
