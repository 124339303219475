import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import '../assets/scss/main.scss';
import Menu from './Menu';

const Layout = ({ children, location }) => {
  let content;

  if (location && location.pathname === '/') {
    content = <div>{children}</div>;
  } else {
    content = (
      <div>
        <Menu />
        <div id="wrapper" className="page">
          <div>{children}</div>
        </div>
      </div>
    );
  }

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              {
                name: 'description',
                content:
                  'hytech llc - reliable partners helping you build a brighter future'
              },
              {
                name: 'keywords',
                content:
                  'software design, programming, architecture, web design and development, app development, cloud platforms, monitoring, ci/cd, code quality & coverage, docker, AWS, Azure, google cloud, javascript, angular, react, dev ops, user experience, virginia, washington dc, maryland'
              }
            ]}
          >
            <html lang="en" />
          </Helmet>
          {content}
        </>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
