import { Link } from 'gatsby';
import React from 'react';
import pic from '../images/logo-white.png';

const Menu = () => (
  <div id="menu">
    <div className="site-links">
      <ul>
        <li>
          <span>
            <Link to="/">Home</Link>
          </span>
          <span>
            <Link to="/services">Services</Link>
          </span>
          <span>
            <Link to="/about-us">About Us</Link>
          </span>
          <span>
            <Link to="/contact-us">Contact Us</Link>
          </span>
        </li>
      </ul>
    </div>
    <div className="site-logo">
      <img src={pic} alt="hytech" />
    </div>
  </div>
);

export default Menu;
